export default function Index() {
  if (typeof window !== 'undefined') {
    const langRegex = /(\/en\/|\/de\/|\/ja\/|\/es-MX\/|\/pt-BR\/|\/ko\/|\/zh-CN\/|\/it\/|\/es\/)/g;
    const langFound = document.location.pathname.match(langRegex);
    if (langFound && langFound.length > 0) {
      const urlLang = langFound[0].substring(1, 3);
      window.location = `/${urlLang}/trailblazercommunity`;
    } else {
      window.location = '/en/trailblazercommunity';
    }
  }

  return null;
}
